import merge from 'lodash.merge';

import type { RecoAction } from 'actions/recos';
import { FETCH_RECOS, RECEIVE_RECOS, RECEIVE_SECONDARY_RECOS } from 'constants/reduxActions';
import { normalizeToPatronLikeProduct } from 'helpers/RecoUtils';
import type { AllJanusSlotNames, JanusData, JanusSlot } from 'types/mafia';

export type JanusRecos = Partial<Record<string, JanusData | Record<string, JanusData>>>;

const DEFAULT_RECO_TITLE = 'Recommendations For You';
export function extractDataFromWidget(widget: JanusSlot): JanusData {
  return {
    title: widget.title || DEFAULT_RECO_TITLE,
    recos: (widget.sims || widget.recs || []).map(reco => {
      const { recoName } = widget;
      const normalized = normalizeToPatronLikeProduct(reco, recoName);
      return { ...normalized, recoName };
    })
  };
}

export interface RecosState {
  isLoading?: boolean;
  lastReceivedRecoKey?: string;
  source?: string;
  janus?: JanusRecos;
}

export default function recos(state: Readonly<RecosState> = {}, action: RecoAction): RecosState {
  const newEntry: JanusRecos = {};

  switch (action.type) {
    case FETCH_RECOS:
      return { ...state, isLoading: true };
    case RECEIVE_RECOS:
      const keys = Object.keys(action.data) as AllJanusSlotNames[];
      // if one widget was requested put the info in the top level for ease of use.
      // TODO I, MT, did not make this decision ^^^^, I merely am typing this file lol
      if (keys.length === 1 && !action.isCFYSslot) {
        const reco = action.data[keys[0]!];
        if (reco) {
          newEntry[action.key] = extractDataFromWidget(reco);
        }
      } else {
        // otherwise nest key = { widgetId: { title, recos } }
        const newData: Record<string, JanusData> = {};
        keys.forEach(prop => {
          const reco = action.data[prop];
          if (reco) {
            newData[prop] = extractDataFromWidget(reco);
          }
        });
        newEntry[action.key] = newData;
      }

      const filteredRecos = state.janus || {};
      // For CFYS slot, we merge with the existing slots
      const newRecoContents = action.isCFYSslot ? merge(filteredRecos, newEntry) : { ...filteredRecos, ...newEntry };

      return {
        ...state,
        isLoading: false,
        janus: { ...newRecoContents },
        lastReceivedRecoKey: action.key,
        source: action.source
      };

    case RECEIVE_SECONDARY_RECOS:
      newEntry[action.key] = action.data;
      return {
        ...state,
        isLoading: false,
        janus: { ...newEntry },
        lastReceivedRecoKey: action.key,
        source: action.source
      };

    default:
      return state;
  }
}

/** Type predicate to determine if the type of JanusRecos in practical usage has nested widget keys */
export function areRecosFlattened(recos: JanusData | Record<string, JanusData>): recos is JanusData {
  return !!(recos as JanusData).recos;
}
