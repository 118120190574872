import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/common/lowStockLabel.scss';

interface LowStockLabelProps {
  onHand: number;
}

const LowStockLabel = ({ onHand }: LowStockLabelProps) => {
  const { testId } = useMartyContext();

  return (
    <p className={css.lowStockLabel} data-test-id={testId('lowStockLabel')} aria-label="Low stock">
      {onHand} left in stock
    </p>
  );
};

export default LowStockLabel;
