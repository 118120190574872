import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { InputInline } from '@mweb/zappos-ui/InputInline';

import useMartyContext from 'hooks/useMartyContext';
import { checkIfValidEmail } from 'utils/subscriptionsUtils';
import Button from 'components/common/Button';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

export const FOOTER_SIGNUP_TITLE = 'Join Our List, Get 10% Off';
export const FOOTER_SIGNUP_DESCRIPTION =
  'Sign up for Zappos emails—new subscribers get 10% off a future order!* Plus, get early access to sales, coupons, and more. (One code per email address.)';

export const FooterSignUp = ({ isFooterSubscribeSubmitted, handleSubscribeSubmit, signUp, resetFooterSubscribeState }) => {
  const isSubmitting = useSelector(state => state.headerFooter?.isSubmitting);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState('Please enter a valid email address');
  const { testId } = useMartyContext();
  const isValidEmail = email => checkIfValidEmail(email);

  if (!signUp) {
    return null;
  }

  const handleSubmit = event => {
    event.preventDefault();

    const email = event.target.email.value;

    if (!isValidEmail(email)) {
      setIsEmailValid(false);
      setInvalidEmailMsg('Please enter a valid email address');
      return;
    }

    setIsEmailValid(true);
    if (!isSubmitting) {
      handleSubscribeSubmit(email, 'footer');
    }
  };

  const handleInputChange = () => {
    if (!isEmailValid) {
      setIsEmailValid(true);
    }

    if (isFooterSubscribeSubmitted) {
      resetFooterSubscribeState(false);
    }
  };

  return (
    <div className="bg-primary-minimal px-5 py-10 sm:px-12 sm:py-8" data-slot-id="sign-up-form" data-test-id={testId('newsletterContainer')}>
      <div className="m-auto flex w-full max-w-[1440px] flex-wrap justify-between gap-y-6">
        <div className="max-w-[670px] self-baseline text-base">
          <div className="flex flex-wrap items-center gap-3">
            <h2 className="text-xl font-semibold text-primary sm:text-2xl">{FOOTER_SIGNUP_TITLE}</h2>
            <p className="text-base">{FOOTER_SIGNUP_DESCRIPTION}</p>
            <form
              className="flex w-full flex-wrap gap-4"
              method="post"
              onSubmit={handleSubmit}
              data-footer-signup
              data-test-id={testId('footerNewsletterForm')}
              action="/subscription.do"
            >
              <div className="flex w-full flex-col gap-4 sm:flex-row">
                <InputInline
                  name="email"
                  label="Email Address"
                  className="w-full self-start sm:w-[455px] sm:max-w-[480px] [&_input]:rounded-lg"
                  disabled={isSubmitting}
                  variant={isEmailValid ? (isFooterSubscribeSubmitted ? 'success' : undefined) : 'error'}
                  helpText={isEmailValid ? (isFooterSubscribeSubmitted ? 'Success! Thank you for subscribing.' : undefined) : invalidEmailMsg}
                  data-test-id={testId('footerNewsletterInput')}
                  id="footerSubEmail"
                  onChange={handleInputChange} // Reset validation when input changes
                />
                <Button
                  type="submit"
                  variant="filled"
                  height="large"
                  className="w-full flex-shrink-0 sm:w-fit"
                  data-test-id={testId('footerNewsletterSubmit')}
                >
                  Join the Party
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterSignUp.contextTypes = {
  testId: PropTypes.func
};

export default withErrorBoundary('FooterSignUp', FooterSignUp);
