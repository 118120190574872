import {
  ADD_UPCOMING_STYLES_QUICK_VIEW_DATA,
  SET_API_STATUS,
  SET_LAUNCH_CALENDAR_RECENT_LAUNCHES,
  SET_LAUNCH_CALENDAR_UPCOMING_STYLES
} from 'constants/reduxActions';
import type { LaunchCalendarRecentLaunches, LaunchCalendarUpcomingStyles, UpcomingStylesQuickViewData } from 'types/launchCalendar';
import type { LaunchCalendarUpcomingProductsAction } from 'actions/launchCalendar';

export interface LaunchCalendarState {
  upcomingStyles: LaunchCalendarUpcomingStyles;
  recentLaunches: LaunchCalendarRecentLaunches;
  upcomingStylesQuickViewData: UpcomingStylesQuickViewData;
  apiStatus: string;
}

const initialState = {
  upcomingStyles: {},
  recentLaunches: {},
  upcomingStylesQuickViewData: {},
  apiStatus: ''
};

export default function launchCalendar(
  state: Readonly<LaunchCalendarState> = initialState,
  action: LaunchCalendarUpcomingProductsAction
): LaunchCalendarState {
  switch (action.type) {
    case SET_LAUNCH_CALENDAR_UPCOMING_STYLES:
      return { ...state, upcomingStyles: action.response };
    case SET_LAUNCH_CALENDAR_RECENT_LAUNCHES:
      return { ...state, recentLaunches: action.response };
    case ADD_UPCOMING_STYLES_QUICK_VIEW_DATA:
      return { ...state, upcomingStylesQuickViewData: { ...state.upcomingStylesQuickViewData, ...action.data } };
    case SET_API_STATUS:
      return { ...state, apiStatus: action.apiStatus };
    default:
      return state;
  }
}
