import type { MouseEventHandler } from 'react';
import React, { forwardRef, useState } from 'react';
import throttle from 'lodash.throttle';

import * as Tooltip from 'components/tooltip/Tooltip'; // eslint-disable-line
import { cn } from 'helpers/classnames';
import { isTouchDevice } from 'helpers/ClientUtils';
import COLOR_SWATCH_MAP from 'constants/colorSwatchMap';
import type { Product, ProductWithStyles } from 'constants/searchTypes';
import type { HeartsData } from 'types/product';
import ProductCard from 'components/common/ProductCard';
import { track } from 'apis/amethyst';
import { evSwatchInteract } from 'events/search';
import type MsaImageParams from 'types/msaImageParams';
import ProductUtils, { getFinalProductUrl, getImageIdFromImageMap, imageMapTypes } from 'helpers/ProductUtils';
import { constructMSAImageUrl, pluralize } from 'helpers/index';
import MartyLink from 'components/common/MartyLink';
import useMartyContext from 'hooks/useMartyContext';
import type { ProductWithRelations } from 'types/calypso';
import type { BadgeData } from 'types/badges';
import { useRefScrollDimensions } from 'hooks/useRefScrollDimensions';
import type { HeartProps } from 'types/hearts';

import css from 'styles/components/search/colorswatches.scss';
import tooltipCss from 'styles/components/common/radixTooltip.scss';

// These are used in calculating available space for swatches to dynamically render them to fill the product card
const SWATCH_SIZE = 34;
const SWATCH_PADDING = 4;
const SWATCH_SIZE_WITH_PADDING = SWATCH_SIZE + SWATCH_PADDING * 2;

export interface ColorSwatchProps {
  relatedStyle: Product;
  isActive: boolean;
  makeSwatchClickHandler: (swatch: Product, interactedSwatchIndex: number) => MouseEventHandler;
  swatchIndexReference: number;
  testId: (id: string) => string;
  useMouseEnterEvent?: boolean;
  hasTooltip?: boolean;
}

interface SwatchesProps extends ProductWithStyles, Pick<ColorSwatchProps, 'useMouseEnterEvent' | 'makeSwatchClickHandler'> {}

const ColorSwatch = forwardRef<HTMLButtonElement, ColorSwatchProps>(
  (
    {
      hasTooltip,
      isActive,
      makeSwatchClickHandler,
      relatedStyle,
      swatchIndexReference,
      testId,
      useMouseEnterEvent,
      ...forwardProps
    }: ColorSwatchProps,
    ref
  ) => {
    const { styleColor, swatchUrl, color, imageMap } = relatedStyle;
    const colorName = COLOR_SWATCH_MAP[color?.toLowerCase()] || 'unknown';

    const imgId = getImageIdFromImageMap(imageMap, imageMapTypes.SWCH);

    const imageSrc = swatchUrl
      ? ProductUtils.replaceJpgToWebpSwatchUrl(swatchUrl)
      : constructMSAImageUrl(imgId, {
          width: 73,
          height: 73,
          autoCrop: true,
          useWebp: true
        });

    return (
      <button
        className={css.swatchButton}
        title={hasTooltip ? undefined : styleColor}
        aria-label={`${isActive ? 'Selected color' : 'Color'}, ${styleColor}, button`}
        type="button"
        onMouseEnter={useMouseEnterEvent ? makeSwatchClickHandler(relatedStyle, swatchIndexReference) : () => {}}
        onClick={makeSwatchClickHandler(relatedStyle, swatchIndexReference)}
        data-test-id={testId('colorSwatchButton')}
        ref={ref}
        {...forwardProps}
      >
        {imageSrc ? (
          <img alt={color} className={cn({ [css.active]: isActive })} height={34} loading="lazy" src={imageSrc} width={34} />
        ) : (
          <span
            className={cn({
              [css.unknown]: colorName === 'unknown',
              [css.active]: isActive
            })}
            style={{ background: colorName }}
          />
        )}
      </button>
    );
  }
);

export const ColorSwatches = ({
  relatedStyles,
  makeSwatchClickHandler,
  colorId: mainColorId,
  styleId: mainStyleId,
  useMouseEnterEvent = true,
  productSeoUrl,
  productUrl,
  onClick
}: SwatchesProps) => {
  const { testId } = useMartyContext();
  const [containerDim, containerRef] = useRefScrollDimensions({ includes: { clientWidth: true } });
  const containerWidth = containerDim?.clientWidth || 0;

  if (!relatedStyles?.length) {
    return <div className={css.wrapper}></div>;
  }

  // remove 1 off the width for the +n grouping
  const numberOfVisibleSwatches = Math.floor((containerWidth - SWATCH_SIZE_WITH_PADDING) / SWATCH_SIZE_WITH_PADDING);

  const { visibleFabricSwatches, groupedSwatches, hydraSwatches } = ProductUtils.groupVisibleSwatches(relatedStyles, numberOfVisibleSwatches);

  let groupedSwatchesProductLink = productSeoUrl ?? productUrl;

  // set the first productSeoUrl to the group swatch link
  if (groupedSwatches.length > 0 && groupedSwatches[0]) {
    const { productSeoUrl, productUrl } = groupedSwatches[0];
    groupedSwatchesProductLink = productSeoUrl ?? productUrl;
  }

  const groupSwatchLength = ProductUtils.getGroupedSwatchLength(groupedSwatches, hydraSwatches);

  /*
   *** DO NOT add any other margins or paddings between the swatches or swatch container,
     number of swatches is calculated via the above SWATCH_SIZE_WITH_PADDING variable, if you need to adjust the padding or size of swatches please do so in the variables const SWATCH_SIZE & SWATCH_PADDING ***
   */

  return (
    <section ref={containerRef} className={css.wrapper}>
      <ul
        style={
          {
            '--color-swatch-size': `${SWATCH_SIZE}px`,
            '--color-swatch-x-padding': `${SWATCH_PADDING}px`,
            '--color-swatch-y-padding': `${SWATCH_PADDING}px`
          } as React.CSSProperties
        }
        className={css.contentClass}
        data-test-id={testId('colorSwatch')}
      >
        {visibleFabricSwatches.map((relatedStyle, i) => (
          <li key={relatedStyle.colorId}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <ColorSwatch
                  relatedStyle={relatedStyle}
                  useMouseEnterEvent={useMouseEnterEvent}
                  makeSwatchClickHandler={makeSwatchClickHandler}
                  swatchIndexReference={i}
                  isActive={mainColorId === relatedStyle.colorId && mainStyleId === relatedStyle.styleId}
                  hasTooltip={true}
                  testId={testId}
                />
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className={tooltipCss.tooltip} side="bottom">
                  {relatedStyle.styleColor}
                  <Tooltip.Arrow className={tooltipCss.arrow} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </li>
        ))}
        {groupedSwatches.length > 0 && (
          <li key="grouped-swatches-button">
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <div>
                  <MartyLink
                    to={groupedSwatchesProductLink}
                    onClick={onClick}
                    aria-label={`Link, ${groupSwatchLength} ${pluralize('more color', groupSwatchLength)}`}
                    className={css.nMoreSwatchesLink}
                  >
                    {groupSwatchLength !== undefined && (
                      <span
                        className={cn({
                          [css.active]: !!groupedSwatches.find(s => mainColorId === s.colorId && mainStyleId === s.styleId)
                        })}
                      >{`+${groupSwatchLength}`}</span>
                    )}
                  </MartyLink>
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className={tooltipCss.tooltip} side="bottom">
                  {`${groupSwatchLength} ${pluralize('more color', groupSwatchLength)}`}
                  <Tooltip.Arrow className={tooltipCss.arrow} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </li>
        )}
        {hydraSwatches.length > 0 && !groupedSwatches.length && !visibleFabricSwatches.length && (
          <li key="hydra-grouped-swatches-button">
            <MartyLink
              className={css.underline}
              to={groupedSwatchesProductLink}
              onClick={onClick}
              aria-label={`Link, ${hydraSwatches.length} ${pluralize('more color', hydraSwatches.length)}`}
            >
              <span
                className={cn(css.groupedSwatches, {
                  [css.active]: !!hydraSwatches.find(s => mainColorId === s.colorId && mainStyleId === s.styleId)
                })}
              >{`+${hydraSwatches.length} ${pluralize('color')}/${pluralize('pattern')}`}</span>
            </MartyLink>
          </li>
        )}
      </ul>
    </section>
  );
};

interface BadgeProps {
  imageBadgeClassName?: string;
  badges?: BadgeData[];
}

interface ProductCardProps {
  shouldLazyLoad?: boolean;
  showPrice?: boolean;
  showColorName?: boolean;
  showRatingStars?: boolean;
  isLaunchCalendarView?: boolean;
  dateLabel?: string;
}

interface BaseWrapperProps extends BadgeProps {
  testId: string | undefined;
  animationTimerIndex?: number;
  heartsInfo?: HeartsData | HeartProps; // todo: remove HeartsData type for HeartProps
  msaImageParams?: MsaImageParams;
  onProductMediaHovered?: (hoveredProduct: Product | ProductWithRelations, mainStyleId: string) => void;
  className?: string;
  imageClassName?: string;
  // landing pages and slots specific
  eventLabel?: string;
  isCrossSiteSearch?: boolean;
  siteName?: string;
  onComponentClick?: (evt: React.MouseEvent<HTMLAnchorElement>, product: Product) => void;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>, styleId?: number) => any;
  retainQueryString?: URLSearchParams;
  productCardProps?: ProductCardProps;
  onSwatchClickHandler?: (focusedProduct: Product | ProductWithRelations) => void;
  isSponsoredAdCard?: boolean;
  isSponsored?: boolean;
}

interface WrapperPropsForProduct extends BaseWrapperProps, ProductWithStyles {
  clickThruUrl?: never;
}

interface WrapperPropsForRecos extends BaseWrapperProps, ProductWithRelations {
  clickThruUrl?: string;
}

export type WrapperProps = WrapperPropsForProduct | WrapperPropsForRecos;

export const getQueryString = (url: string) => {
  // baseurl doesnt matter
  const u = new URL(`https://www.zappos.com${url}`);
  return u.searchParams;
};

const ColorSwatchWrapper = ({
  testId,
  animationTimerIndex,
  heartsInfo,
  onProductMediaHovered,
  onSwatchClickHandler,
  className,
  eventLabel,
  isCrossSiteSearch,
  siteName,
  onComponentClick,
  onClick,
  retainQueryString,
  isSponsored,
  productCardProps,
  clickThruUrl,
  ...mainProduct
}: WrapperProps) => {
  const {
    marketplace: {
      search: { enableColorSwatches }
    }
  } = useMartyContext();
  const [currentProduct, setCurrentProduct] = useState<Product | ProductWithRelations>(mainProduct);
  // the original product can be loaded w/ params important for reco tracking. On swatch changes, these params are lost as they dont exist on the swatch url.
  // we need to capture the original params and append them later if they exist.
  const [originalQueryString] = useState<URLSearchParams>(retainQueryString || getQueryString(mainProduct.productUrl));
  const { styleId } = mainProduct;

  const makeSwatchClickHandler = (swatch: Product | ProductWithRelations, interactedSwatchIndex: number) =>
    throttle(e => {
      const touchDevice = isTouchDevice();
      const newProduct = { ...mainProduct, ...swatch };
      if ((!touchDevice && e.type === 'mouseenter') || e.type === 'click') {
        const interactionType = e.type === 'mouseenter' ? 'HOVER' : 'CLICK';
        track(() => [
          evSwatchInteract,
          {
            mainStyleId: styleId,
            interactedProduct: swatch,
            interactedSwatchIndex,
            interactionType
          }
        ]);
      }
      setCurrentProduct(newProduct);
      onSwatchClickHandler?.(swatch);
    }, 500);

  const makeProductMediaHoverHandler = () => {
    onProductMediaHovered?.(currentProduct, styleId);
  };

  const { productUrl: currentProductUrl } = currentProduct;

  const finalProductUrl = getFinalProductUrl(originalQueryString, currentProductUrl);

  const hearts = ((heartsInfo as HeartsData)?.heartsData || heartsInfo) as HeartProps;

  return (
    <ProductCard
      {...currentProduct}
      {...productCardProps}
      productUrl={finalProductUrl}
      imageClassName={mainProduct.rowHasBadge && css.rowHasBadge}
      onMediaHovered={makeProductMediaHoverHandler}
      data-test-id={testId}
      hearts={hearts}
      CardDetailsTopSlot={enableColorSwatches ? ColorSwatches : undefined}
      makeSwatchClickHandler={makeSwatchClickHandler}
      animationTimerIndex={animationTimerIndex}
      className={className}
      eventLabel={eventLabel}
      isCrossSiteSearch={isCrossSiteSearch}
      siteName={siteName}
      onComponentClick={onComponentClick}
      onClick={onClick}
      isSponsored={isSponsored}
      clickThruUrl={clickThruUrl}
    />
  );
};

export default ColorSwatchWrapper;
